<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- Offer Information -->
    <b-row class="d-flex justify-content-center" style="margin-top: 90px;">
      <b-col md="6">
        <b-card title="Coupon Details">
          <h6 class="text-muted">Code: {{ couponData.code }}</h6>
          <h6 class="text-muted">
            Status: <b-badge variant="primary">{{ couponData.active }}</b-badge>
          </h6>
          <h6 class="text-muted">Start Price: {{ couponData.startprice }}</h6>
          <h6 class="text-muted">End Price: {{ couponData.endprice }}</h6>
          <h6 class="text-muted">Start Date: {{ couponData.startdate }}</h6>
          <h6 class="text-muted">End Date: {{ couponData.enddate }}</h6>
          <h6 class="text-muted">User Numbers: {{ couponData.usernumbers }}</h6>
          <h6 class="text-muted">Type: {{ couponData.type }}</h6>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <div v-if="delCat" class="d-flex justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              :to="{ path: '/Coupon/Update/' + couponData.id }"
              variant="primary mr-1 mt-1"
            >
              Update
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('delete')"
              @click="deleteDataItem(couponData.id)"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Delete
            </b-button>
          </div>
          <div v-else class="d-flex justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              @click="restorData(couponData.id)"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Restore
            </b-button>
          </div>
          <hr />
          <div
            v-if="delCat"
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ couponData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Updated at
              </h6>
              <h3 class="mb-0">
                {{ couponData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ couponData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Deleted at
              </h6>
              <h3 class="mb-0">
                {{ couponData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Description">
          <b-tabs align="center" class="text-center">
            <b-tab
              v-for="(translation, transIndex) in itemTrans"
              :title="translation.locale == 'en' ? 'English' : 'Arabic'"
              :key="transIndex"
            >
              <b-card-text v-html="translation.description"></b-card-text>
              <b-button
                v-if="authPermissions.includes('update')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="warning"
                class="btn-cart"
                :to="{
                  name: 'Coupon-Update-Description',
                  params: { id: couponData.id, language: translation.locale },
                }"
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span>Update {{ translation.locale }} translation text</span>
              </b-button>
              <b-button
                v-if="
                  authPermissions.includes('delete') &&
                  translation.locale != 'en' &&
                  translation.locale != 'ar'
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="btn-cart ml-1"
                @click="deleteData(couponData.id, translation.locale)"
              >
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span>Delete {{ translation.locale }} translation text</span>
              </b-button>
            </b-tab>
          </b-tabs>
          <!-- add new translation -->
          <b-button
            v-if="authPermissions.includes('create')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart mt-3"
            :to="{
              name: 'Coupon-Add-Description',
              params: { id: couponData.id },
            }"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Add new translation to text</span>
          </b-button>
        </b-card>

        <!-- <b-card title="Descriptions">
                    
                    <b-table striped hover :items="items" :fields="fields">
                        <template #cell(actions)="data">
                            <b-button style='padding:7px; margin:6px;' v-if="authPermissions.includes('update')" :to="{name: 'Coupon-Update-Description', params:{id: couponData.id,language: data.item.locale}}" variant="warning">Update</b-button>
                            <b-button style='padding:7px; margin:6px;' v-if="authPermissions.includes('delete')" @click="deleteData(couponData.id, data.item.locale)" variant="danger">Delete</b-button>
                        </template>
                        <template #cell(value)="data">
                           <b-card-text v-html="data.item.value">
                               
                           </b-card-text>
                        </template>
                    </b-table>
                     <b-button style='padding:7px; margin:6px;' block v-if="authPermissions.includes('update')" :to="{name: 'Coupon-Add-Description', params:{id: couponData.id}}" variant="success">Add new translation to text</b-button>
                </b-card> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="items.length > 0" class="" cols="12">
        <b-card title="Items List">
          <b-table striped hover :items="items" :fields="fields">
            <template #cell(status)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col v-if="bundles.length > 0" cols="12">
        <b-card title="Bundles">
          <b-table-simple hover small responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th colspan="4">
                  Bundle
                </b-th>
                <b-th colspan="1">
                  Bundle Price
                </b-th>
                <b-th colspan="1">
                  Bundle Original Price
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in bundles" :key="index">
              <b-tr>
                <b-th colspan="4">
                  {{ item.title }}
                </b-th>
                <b-td colspan="1"> {{ item.bundle_price }} EGP </b-td>
                <b-td colspan="1"> {{ item.original_price }} EGP </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      authPermissions: [],
      id: 0,
      couponData: {},
      errors_back: [],
      showDismissibleAlert: false,
      items: [],
      // fields: ['id', 'locale', 'key','value', 'actions'],
      itemTrans: [],
      bundles: [],

      fields: [
        {
          key: 'product',
          label: 'Product',
        },

        {
          key: 'price',
          label: 'Price',
        },

        {
          key: 'status',
          label: 'Status',
        },
      ],
      delCat: true,
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('coupons/' + this.id)
        .then((result) => {
          this.couponData = result.data.data
          if (this.couponData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
          this.bundles = this.couponData.bundles
          for (let index in this.couponData.variants) {
            this.items.push({
              product: this.couponData.variants[index].name,
              price: this.couponData.variants[index].price + ' EGP',
              status: this.couponData.variants[index].status,
            })
          }
          if (this.couponData.translations.length > 0) {
            this.itemTrans = this.couponData.translations
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    deleteDataItem(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete coupon.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('coupons/' + id)
              .then((result) => {
                this.fetchData()
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })

                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restorData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore coupon.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('coupons/' + id + '/restore')
              .then((result) => {
                this.fetchData()
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteData(id, locale) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to delete coupon description language.',
          {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          axios
            .delete('coupons/' + id + '/' + locale + '/destroy')
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.fetchData()
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
